import AboutSection from "../components/AboutSection";
import Banner from "../components/Banner";
// import CalculatorSection from "../components/CalculatorSection";
import FeatureSection from "../components/FeatureSection";
// import VideoSection from "../components/VidevoSection";
import FunFactSection from "../components/FunFactSection";
import TestimonialSection from "../components/TestimonialSection";
import NewsSection from "../components/NewsSection";
import ProcessSection from "../components/ProcessSection";
import Services from "../components/Services";

const Home = () => {
  return (
    <div className="boxed_wrapper ltr">
      <Banner />

      <FeatureSection />

      <AboutSection isHomePage={true} />

      <ProcessSection />

      <FunFactSection />

      <Services />

      {/* <CalculatorSection /> */}

      {/* <VideoSection /> */}

      {/* <TestimonialSection /> */}

      <NewsSection />
    </div>
  );
};

export default Home;
