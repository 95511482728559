import PageTitle from "../components/custom/PageTitle";

function CorporateValue() {
  return (
    <div className="box_wrapper ltr">
      <PageTitle
        title="Corporate Value"
        breadcrumbs={[{ text: "Home", link: "/" }, { text: "Corporate Value" }]}
      />

      <div className="auto-container">
        <div className="corporate-value">
          <img
            src="assets/images/service/CorporateValue.jpg"
            alt="AccessBank Liberia Building"
            className="corporate-value-image"
          />
          <div className="corporate-value-content">
            <h2>MISSION STATEMENT</h2>
            <p>
              ABL’s mission is to become the bank of choice for the low and
              middle-income strata of the Liberian population, and the country’s
              leading provider of financial services to micro, small, and
              medium-sized enterprises.
            </p>

            <h2>VISION STATEMENT</h2>
            <p>
              Our Vision is a financial sector that offers responsible financial
              services to all people with the same ambition for excellence and
              quality.
            </p>

            <h2>PRINCIPLES</h2>
            <p>
              As a member bank of the AccessGroup network, ABL shares a range of
              core-standardized products (with local adaptations as required)
              and the following principles:
            </p>
            <ul>
              <li>
                Ease and speed of access – providing financial services to
                previously un-or under-served parts of the population, with the
                aim to be faster and more efficient than any comparable operator
                in the country.
              </li>
              <li>
                Transparency and clarity – communicating all products, services,
                and eligibility criteria with as little room for subjective
                interpretation as possible.
              </li>
              <li>
                Quality leadership – setting industry standards with respect to
                customer service and quality, ensuring the highest quality loan
                portfolios possible, through adherence to international best
                practices with respect to their underwriting standards and
                credit risk management.
              </li>
              <li>
                Training and staff development – recruiting talented and
                motivated staff on a large scale that do not necessarily have
                relevant work experience and qualifying them to assume more
                responsible roles over time through regular training and
                coaching as well as frequent staff exchanges between network
                banks.
              </li>
              <li>
                Strong business ethics – adhering to a joint set of principles
                of good corporate behavior, covering responsible lending
                practices, adherence to social and environmental standards, both
                international and local, as well as a stringent code of conduct
                for all member-bank employees.
              </li>
            </ul>

            <h2>CODE OF CONDUCT</h2>
            <p>
              The principles that inform all business activities of AccessBank
              Liberia are the following:
            </p>
            <ul>
              <li>Customer and Service Orientation</li>
              <li>Efficiency and Standardization</li>
              <li>Prudence</li>
              <li>Transparency</li>
              <li>Product Development and Diversification</li>
              <li>Social Responsibility and Tolerance</li>
            </ul>

            <h2>Our Core Values</h2>
            <p>At the heart of our operations are the following core values:</p>
            <ul>
              <li>
                <strong>Ethics</strong>: We conduct all business with integrity,
                fairness, and respect for all stakeholders.
              </li>
              <li>
                <strong>Employee Development</strong>: We are committed to
                empowering and supporting our team members through continuous
                growth and development opportunities.
              </li>
              <li>
                <strong>Communication</strong> :We prioritize clear, open, and
                effective communication both internally and externally.
              </li>
              <li>
                <strong>Professionalism</strong>: We uphold the highest
                standards of professionalism in every interaction and decision.
              </li>
              <li>
                <strong>Customer Obsession</strong>: Our customers are our top
                priority, and we are dedicated to delivering exceptional service
                and solutions that meet their needs.
              </li>
              <li>
                <strong>Transparency</strong>: We operate with openness and
                honesty, ensuring accountability and trust.
              </li>
              <li>
                <strong>Results-Oriented</strong>: We are focused on achieving
                tangible, positive outcomes in everything we do.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CorporateValue;
