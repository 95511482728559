import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchBlogById, fetchBlogs } from "../redux/actions/blogs";
// import { fetchComments, addComment } from "../redux/actions/comments";
import PageTitle from "../components/custom/PageTitle";

function BlogDetails() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { items: blogs, status, error } = useSelector((state) => state.blogs);
  // const [recentBlogs, setRecentBlogs] = useState([]);
  // const {
  //   comments,
  //   loading: commentsLoading,
  //   error: commentsError,
  // } = useSelector((state) => state.comments);
  // const [comment, setComment] = useState({ name: "", email: "", content: "" });

  useEffect(() => {
    if (id) {
      dispatch(fetchBlogById(id));
      dispatch(fetchBlogs());
    }
  }, [id, dispatch]);

  // useEffect(() => {
  //   if (blogs.length > 0) {
  //     setRecentBlogs(blogs.filter((blog) => blog._id !== id).slice(0, 3));
  //   }
  // }, [blogs, id]);

  // const handleCommentChange = (e) => {
  //   setComment({ ...comment, [e.target.name]: e.target.value });
  // };

  // const handleCommentSubmit = (e) => {
  //   e.preventDefault();
  //   dispatch(addComment({ ...comment, blog: id }));
  //   setComment({ name: "", email: "", content: "" });
  // };

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  const blog = blogs.find((blog) => blog._id === id);

  if (status === "failed") {
    return <div>Error: {error}</div>;
  }

  if (!blog) {
    return <div>Blog post not found</div>;
  }

  return (
    <div className="boxed_wrapper ltr">
      <PageTitle
        title="Blog Detail"
        breadcrumbs={[{ text: "Home", link: "/" }, { text: "Blog Detail" }]}
      />

      <section className="sidebar-page-container pt_120 pb_120">
        <div className="auto-container">
          <div className="row clearfix">
            <div className="col-lg-12 col-md-12 col-sm-12 content-side">
              <div className="blog-details-content">
                <div className="news-block-three">
                  <div className="inner-box">
                    <figure className="image-box">
                      <img
                        src={blog.blogImage}
                        alt={blog.title}
                        style={{ height: "450px", width: "100%" }}
                      />
                    </figure>
                    <div className="lower-content">
                      <div className="post-date">
                        <h4>
                          {new Date(blog.date).getDate()}
                          <span>
                            {new Date(blog.date).toLocaleString("default", {
                              month: "short",
                            })}
                          </span>
                        </h4>
                      </div>

                      <h3>{blog.title}</h3>
                      {blog.content.openingParagraphs.map(
                        (openingParagraph, index) => (
                          <p key={index}>{openingParagraph}</p>
                        )
                      )}
                      {blog.content.bulletPoints &&
                        blog.content.bulletPoints.length > 0 && (
                          <ul className="list-item mb_50 clearfix">
                            {blog.content.bulletPoints.map((point, index) => (
                              <li key={index}>{point}</li>
                            ))}
                          </ul>
                        )}
                      {blog.content.closingParagraphs.map(
                        (closingParagraph, index) => (
                          <p key={index}>{closingParagraph}</p>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="author-box mb_60">
                  <figure className="author-thumb">
                    <img src={blog.author.image} alt={blog.author.name} />
                  </figure>
                  <h4>{blog.author.name}</h4>
                  <span>About Author</span>
                  <p>{blog.author.description}</p>
                </div>

                {/* <div className="comment-box">
                  <h3>Comments</h3>
                  {commentsError && (
                    <div className="error">{commentsError}</div>
                  )}
                  {comments.map((comment) => (
                    <div key={comment._id} className="comment">
                      <h4>{comment.author}</h4>
                      <p>{comment.content}</p>
                      <span>{new Date(comment.date).toLocaleDateString()}</span>
                    </div>
                  ))}
                </div> */}

                {/* <div className="comment-form-area">
                  <h3>Leave a Comment</h3>
                  <div className="form-inner">
                    <form onSubmit={handleCommentSubmit}>
                      <div className="row clearfix">
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <input
                            type="text"
                            name="name"
                            placeholder="Your name"
                            required
                            value={comment.name}
                            onChange={handleCommentChange}
                          />
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Your email"
                            required
                            value={comment.email}
                            onChange={handleCommentChange}
                          />
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 form-group">
                          <textarea
                            name="content"
                            placeholder="Type message"
                            value={comment.content}
                            onChange={handleCommentChange}
                          ></textarea>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 form-group message-btn">
                          <button type="submit" className="theme-btn btn-one">
                            Submit Comment
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div> */}
              </div>
            </div>
            {/* <div className="col-lg-4 col-md-12 col-sm-12 sidebar-side">
              <div className="blog-sidebar ml_20">
                {/* <div className="sidebar-widget search-widget">
                  <div className="widget-title">
                    <h3>Search</h3>
                  </div>
                  <div className="search-inner">
                    <form method="post" action="blog-3.html">
                      <div className="form-group">
                        <input
                          type="search"
                          name="search-field"
                          placeholder="Search..."
                          required
                        />
                        <button type="submit">
                          <i className="icon-42"></i>
                        </button>
                      </div>
                    </form>
                  </div>
                </div> */}

            {/* <div className="sidebar-widget post-widget">
                  <div className="widget-title">
                    <h3>Latest News</h3>
                  </div>
                  <div className="post-inner">
                    {recentBlogs.length > 0 ? (
                      recentBlogs.map((blog) => (
                        <div key={blog._id} className="post">
                          <figure className="post-thumb">
                            <a href={`/blog-details/${blog._id}`}>
                              <img src={blog.blogImage} alt={blog.title} />
                            </a>
                          </figure>
                          <h5>
                            <a href={`/blog-details/${blog._id}`}>
                              {blog.title}
                            </a>
                          </h5>
                          <span className="post-date">
                            <i className="icon-27"></i>
                            {new Date(blog.date).toLocaleDateString()}
                          </span>
                        </div>
                      ))
                    ) : (
                      <div>Loading............</div>
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default BlogDetails;
