import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import PreLoader from "./components/PreLoader";
import Navigation from "./components/Navigation";
// import SubscribeSection from "./components/SubscribeSection";
import Footer from "./components/Footer";
import BackToTop from "./components/BackToTop";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ShareHolders from "./pages/ShareHolders";
import BusinessPrinciples from "./pages/BusinessPrinciples";
import CorporateValue from "./pages/CorporateValue";
import ManagementBoard from "./pages/ManagementBoard";
import BoardOfDirectors from "./pages/BoardOfDirectors";
import TeamDetails from "./pages/TeamDetails";
import AnnualReport from "./pages/AnnualReport";
import ContactUs from "./pages/ContactUs";
import BusinessBanking from "./pages/BusinessBanking";
import PersonalBanking from "./pages/PersonalBanking";
import SMEBanking from "./pages/SMEBusiness";
import Tariff from "./pages/Tariff";
import Career from "./pages/Career";
import CareerDetails from "./pages/CareerDetails";
import FAQs from "./pages/FAQs";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import NotFound from "./pages/NotFound";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <PreLoader />
        <Navigation />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/corporate-value" element={<CorporateValue />} />
          <Route path="/share-holders" element={<ShareHolders />} />
          <Route path="/business-principles" element={<BusinessPrinciples />} />
          <Route path="/management-board" element={<ManagementBoard />} />
          <Route path="/management-board/:id" element={<TeamDetails />} />
          <Route path="/board-of-directors" element={<BoardOfDirectors />} />
          <Route path="/board-of-directors/:id" element={<TeamDetails />} />
          <Route path="/annual-report" element={<AnnualReport />} />
          <Route path="/business-banking" element={<BusinessBanking />} />
          <Route path="/personal-banking" element={<PersonalBanking />} />
          <Route path="/sme-banking" element={<SMEBanking />} />
          <Route path="/tariff" element={<Tariff />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faqs" element={<FAQs />} />
          <Route path="/career" element={<Career />} />
          <Route path="/career/:id" element={<CareerDetails />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blogs/:id" element={<BlogDetails />} />
          <Route path="/notfound" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        {/* <SubscribeSection /> */}
        <Footer />
        <BackToTop />
      </Router>
    </Provider>
  );
}

export default App;
