import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTestimonials } from "../redux/actions/testimonials";
import OwlCarousel from "react-owl-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import TestimonialItem from "../components/custom/TestimonialItem";

function TestimonialSection() {
  const dispatch = useDispatch();
  const {
    items: testimonials,
    status,
    error,
  } = useSelector((state) => state.testimonials);

  const carouselRef = useRef(null);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTestimonials());
    }
  }, [status, dispatch]);

  const options = {
    loop: true,
    margin: 30,
    nav: false,
    smartSpeed: 500,
    autoplay: 1000,
    responsive: {
      0: { items: 1 },
      480: { items: 1 },
      600: { items: 1 },
      800: { items: 2 },
      1200: { items: 2 },
    },
  };

  const handlePrev = () => {
    carouselRef.current.prev();
  };

  const handleNext = () => {
    carouselRef.current.next();
  };

  const approvedTestimonails = testimonials.filter(
    (testimonial) => testimonial.status === "approve"
  );

  return (
    <section id="testimonial" className="testimonial-style-two pt_120 pb_120">
      <div
        className="bg-layer"
        style={{
          backgroundImage: "url(assets/images/background/testimonial-bg-2.jpg)",
        }}
      ></div>
      <div className="auto-container">
        <div className="row clearfix">
          <div className="col-lg-4 col-md-12 col-sm-12 title-column">
            <div className="sec-title mr_70">
              <h6>Testimonials</h6>
              <h2>Love from Happy Clients</h2>
              <p>
                Amet dui scelerisque habitant eget tincidunt facilisis pretium
                lorem ipsum dilore.
              </p>
              <div className="owl-icons">
                <button className="owl-prev" onClick={handlePrev}>
                  <FontAwesomeIcon icon={faArrowLeft} className="iconSize" />
                </button>
                <button className="owl-next" onClick={handleNext}>
                  <FontAwesomeIcon icon={faArrowRight} className="iconSize" />
                </button>
              </div>
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12 content-column">
            {status === "loading" ? (
              <>
                <h1>Loading.........</h1>
              </>
            ) : status === "succeeded" ? (
              approvedTestimonails.lenght > 0 ? (
                <OwlCarousel
                  className="two-item-carousel owl-carousel owl-theme"
                  {...options}
                  ref={carouselRef}
                >
                  {approvedTestimonails.map((testimonial) => (
                    <TestimonialItem
                      key={testimonial._id}
                      testimonial={testimonial}
                    />
                  ))}
                </OwlCarousel>
              ) : (
                <div>
                  <h1>No approved Testimonials available</h1>
                </div>
              )
            ) : status === "failed" && error ? (
              <div className="w-full h-1/3 mt-5 mb-5">
                <div className="bg-white py-10 dark:bg-dark">
                  <div className="container">
                    <div className="flex w-full rounded-lg border-l-[6px] border-[#F55F58] bg-[#FFF1F0] p-4 dark:bg-[#575558] md:p-[30px]">
                      <div className="mr-5 text-[#F55F58]">
                        <svg
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          className="fill-current"
                        >
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M16.9999 2.83366C9.57774 2.83366 3.83325 8.57815 3.83325 16.0003C3.83325 23.4225 9.57774 29.167 16.9999 29.167C24.4221 29.167 30.1666 23.4225 30.1666 16.0003C30.1666 8.57815 24.4221 2.83366 16.9999 2.83366ZM0.333252 16.0003C0.333252 7.54021 7.53979 0.33366 15.9999 0.33366C24.46 0.33366 31.6666 7.54021 31.6666 16.0003C31.6666 24.4604 24.46 31.667 15.9999 31.667C7.53979 31.667 0.333252 24.4604 0.333252 16.0003ZM17.8333 8.83366C17.8333 8.18138 17.3182 7.66699 16.6666 7.66699C16.0143 7.66699 15.4999 8.18138 15.4999 8.83366V18.0846C15.4999 18.7369 16.0143 19.2513 16.6666 19.2513C17.3182 19.2513 17.8333 18.7369 17.8333 18.0846V8.83366ZM16.6666 24.5003C17.3182 24.5003 17.8333 23.9859 17.8333 23.3337C17.8333 22.6814 17.3182 22.167 16.6666 22.167C16.0143 22.167 15.4999 22.6814 15.4999 23.3337C15.4999 23.9859 16.0143 24.5003 16.6666 24.5003Z"
                          ></path>
                        </svg>
                      </div>
                      <div className="w-full mb-5">
                        <div className="mb-2 flex items-center justify-between">
                          <h5 className="text-lg font-medium leading-tight text-[#CD3D3A]">
                            {error.message}
                          </h5>
                        </div>
                        <p className="text-base leading-relaxed text-body-color">
                          Please try again later.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}

export default TestimonialSection;
