import React from "react";

const TeamMemberSkeleton = () => {
  const styles = {
    container: {
      width: "100%",
      height: "400px",
      backgroundColor: "#f0f0f0",
      borderRadius: "8px",
      overflow: "hidden",
    },
    imageBox: {
      width: "100%",
      height: "70%",
      backgroundColor: "#e0e0e0",
    },
    content: {
      padding: "20px",
    },
    name: {
      height: "24px",
      width: "80%",
      backgroundColor: "#e0e0e0",
      marginBottom: "10px",
    },
    position: {
      height: "18px",
      width: "60%",
      backgroundColor: "#e0e0e0",
    },
  };

  const pulseAnimation = `
    @keyframes pulse {
      0% {
        opacity: 0.6;
      }
      50% {
        opacity: 1;
      }
      100% {
        opacity: 0.6;
      }
    }
  `;

  return (
    <div className="col-lg-3 col-md-6 col-sm-12 team-block">
      <style>{pulseAnimation}</style>
      <div style={styles.container}>
        <div
          style={{ ...styles.imageBox, animation: "pulse 1.5s infinite" }}
        ></div>
        <div style={styles.content}>
          <div
            style={{ ...styles.name, animation: "pulse 1.5s infinite" }}
          ></div>
          <div
            style={{ ...styles.position, animation: "pulse 1.5s infinite" }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberSkeleton;
