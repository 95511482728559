import { Link } from "react-router-dom";

const NewsBlock = ({ post }) => {
  // Function to truncate content to 14 words
  const truncateContent = (content, wordLimit) => {
    const words = content.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return content;
  };

  return (
    <div
      className="news-block-two wow fadeInUp animated"
      data-wow-delay="00ms"
      data-wow-duration="1500ms"
    >
      <div className="inner-box">
        <figure className="image-box">
          <Link to={`/blogs/${post?._id}`}>
            <img src={post?.blogImage} alt={post?.title} />
          </Link>
        </figure>
        <div className="lower-content">
          <div className="post-date">
            <h4>
              {new Date(post?.date).getDate()}
              <span>
                {new Date(post?.date).toLocaleString("default", {
                  month: "short",
                })}
              </span>
            </h4>
          </div>
          <h3>
            <Link to={`/blogs/${post._id}`}>{post?.title}</Link>
          </h3>
          <p>{truncateContent(post?.content?.openingParagraphs[0] || "", 15)}</p>
          <ul className="post-info">
            <li>
              <i className="icon-32"></i>
              <Link to={`/blogs/${post._id}`}>{post?.author?.name}</Link>
            </li>
            <li>
              <i className="icon-33"></i>
              {post.commentsCount} Comment{post?.commentCount !== 1 ? "s" : ""}
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NewsBlock;
