import { useEffect } from "react";
import PageTitle from "../components/custom/PageTitle";
import { useSelector, useDispatch } from "react-redux";
import TeamMember from "../components/custom/TeamMember";
// import TestimonialSection from "../components/TestimonialSection";
import { fetchTeamMembers } from "../redux/actions/teams";
import TeamMemberSkeleton from "../components/custom/skeletonLoader/TeamMemberSkeleton";

function BoardOfDirectors() {
  const dispatch = useDispatch();
  const { items: teams, status, error } = useSelector((state) => state.teams);

  useEffect(() => {
    dispatch(fetchTeamMembers());
  }, [dispatch]);

  const directors = teams.filter(
    (member) => member.boardType === "Directors" && member.status === "approve"
  );

  return (
    <div className="boxed_wrapper ltr">
      <PageTitle
        title="Board of Directors"
        breadcrumbs={[
          { text: "Home", link: "/" },
          { text: "Board of Directors" },
        ]}
      />

      <section className="team-section pt_120 pb_75">
        <div className="auto-container">
          <div className="sec-title mb_70 centred">
            <h6>Our People</h6>
            <h2>Our Board Members</h2>
          </div>
          <div className="row clearfix">
            {status === "loading" ? (
              Array.from({ length: 4 }).map((_, index) => (
                <TeamMemberSkeleton key={index} />
              ))
            ) : status === "succeeded" ? (
              directors.length > 0 ? (
                directors.map((director) => (
                  <TeamMember
                    key={director._id}
                    id={director._id}
                    name={director.name}
                    position={director.position}
                    image={director.teamMemberImage}
                    socialLinks={director.socialMediaLinks}
                    boardType="board-of-directors"
                  />
                ))
              ) : (
                <div>No Approved Team Member Available</div>
              )
            ) : status === "failed" && error ? (
              <div className="w-100 h-33 my-5">
                <div className="bg-white py-5">
                  <div className="container">
                    <div className="d-flex w-100 rounded border-start border-danger border-5 bg-light p-4">
                      <div className="me-3 text-danger">
                        <svg
                          width="34"
                          height="34"
                          viewBox="0 0 34 34"
                          className="fill-current"
                        >
                          <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M16.9999 2.83366C9.57774 2.83366 3.83325 8.57815 3.83325 16.0003C3.83325 23.4225 9.57774 29.167 16.9999 29.167C24.4221 29.167 30.1666 23.4225 30.1666 16.0003C30.1666 8.57815 24.4221 2.83366 16.9999 2.83366ZM0.333252 16.0003C0.333252 7.54021 7.53979 0.33366 15.9999 0.33366C24.46 0.33366 31.6666 7.54021 31.6666 16.0003C31.6666 24.4604 24.46 31.667 15.9999 31.667C7.53979 31.667 0.333252 24.4604 0.333252 16.0003ZM17.8333 8.83366C17.8333 8.18138 17.3182 7.66699 16.6666 7.66699C16.0143 7.66699 15.4999 8.18138 15.4999 8.83366V18.0846C15.4999 18.7369 16.0143 19.2513 16.6666 19.2513C17.3182 19.2513 17.8333 18.7369 17.8333 18.0846V8.83366ZM16.6666 24.5003C17.3182 24.5003 17.8333 23.9859 17.8333 23.3337C17.8333 22.6814 17.3182 22.167 16.6666 22.167C16.0143 22.167 15.4999 22.6814 15.4999 23.3337C15.4999 23.9859 16.0143 24.5003 16.6666 24.5003Z"
                          ></path>
                        </svg>
                      </div>
                      <div className="w-100 mb-3">
                        <div className="mb-2 d-flex align-items-center justify-content-between">
                          <h5 className="fs-5 fw-medium text-danger">
                            {error.message}
                          </h5>
                        </div>
                        <p className="text-body">Please try again later.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>

      {/* <TestimonialSection /> */}
    </div>
  );
}

export default BoardOfDirectors;
